Object.defineProperty(exports, "__esModule", {
  value: true,
})

exports.urlGetValidations = "profile/validations"
exports.validationApiContentType = "application/json"
exports.validationApiMethodType = "GET"
exports.loginAPiEndPoint = "/admin/v1/login"
exports.forgotAPiEndPoint = "/admin/v1/forgot_password"
exports.otpValidateAPIEndPoint = "/admin/v1/forgot_password/otp_validate"
exports.loginAPiMethod = "POST"
exports.loginApiContentType = "application/json"
exports.dashboardOnbordingAPI = "/admin/v1/onboarding"
// Customizable Area Start
exports.getPackagesAPiEndPoint = "admin/v1/packages?page=1&per_page=100"
exports.getPackagesApiMethodType = "GET"
exports.orderApiEndpoint = "admin/v1/orders"
exports.orderOnShipApiMethodType = "POST"
exports.updateOrderApiMethodType = "PUT"
exports.updateDeliveryAddressApiMethodType = "PUT"

exports.downloadCSVReportApiEndpoint = "/admin/v1/orders/download_csv_report"
exports.downloadCSVReportApiMethodType = "GET"
exports.postBulkDeleteApiEndpoint = "admin/v1/batch_requests"
exports.postBulkDeleteApiMethodType = "POST"
exports.orderDetailURL = "/orders/orderDetail"

exports.errorEmailNotValid = "Email not valid."
exports.errorPasswordNotValid = "Password not valid."
exports.placeHolderEmail = "Email"
exports.placeHolderPassword = "Password"
exports.labelHeader = "The reason we require login"
exports.btnTxtLogin = "LOG IN"
exports.labelRememberMe = "Remember me"
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA"
exports.labelOr = "OR"
exports.labelTitle = "Log in"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png"
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png"

exports.packagingDetails = "Packaging details"
exports.weightLabel = "Weight (kg)"
exports.packageLabel = "Package type"
exports.dimensionsLabel = "Dimensions"
exports.lengthPlaceholder = "Length"
exports.widthPlaceholder = "Width"
exports.heightPlaceholder = "Height"
exports.thisFieldRequired = "This field required"
exports.mustBeMoreThan_0kg = "Must be more than 0kg"
exports.thisFieldIsRequired = "This field is required."
exports.maximum_30Characters = "Maximum 30 characters"
exports.maximum_10Characters = "Maximum 10 characters"

exports.nameLabel = "Name"
exports.namePlaceholder = "Name"
exports.countryLabel = "Country"
exports.countryPlaceholder = "Enter the country"
exports.stateLabel = "State"
exports.phonePlaceholder = "Enter phone number"
exports.passwordLabel = "Password"

exports.cityLabel = "City"
exports.flatNoLabel = "Flat no"
exports.addressLabel = "Address"
exports.zipCodeLabel = "Zip code"
exports.phoneNumberLabel = "Phone number"
exports.addressForLabel = "Address For"
exports.cancelButton = "Cancel"
exports.saveButton = "Save"

exports.editDeliveryAddress = "Edit delivery address"
exports.editAddress = "Edit Address"
exports.backButton = "Back"
exports.orderDetails = "Order Details"
exports.downloadInvoice = "Download Invoice"
exports.orderNumber = "Order number"
exports.orderTotal = "ORDER TOTAL"
exports.orderDate = "Order date"
exports.customer = "CUSTOMER"
exports.statusLabel = "Status"
exports.itemsLabel = "Items"
exports.itemLabel = "item"
exports.quantityLabel = "Quantity"
exports.unitPriceLabel = "Unit price"
exports.totalPriceLabel = "Total price"

exports.subTotalLabel = "Sub total"
exports.paymentMethodLabel = "Payment method"
exports.couponCodeLabel = "Coupon code"
exports.deliveryChargesLabel = "Delivery charges"
exports.appliedDiscountLabel = "Applied discount"
exports.taxesLabel = "Taxes"
exports.totalLabel = "Total"

exports.deliveryAddress = "Delivery address"
exports.shipOrder = "Ship order"
exports.trackShipOrder = "Track order"


exports.orderShippedSuccessfully = "Order shipped successfully!"

exports.imgPasswdVisiblePath = "imgPasswordVisiblePath"
exports.shipOrderError = "Ship Order Error"

exports.initialOrderStatuses = [
  "placed",
  "confirmed",
  "delivered",
  "cancelled",
  "refunded",
  "returned",
  "in_transit",
  "processing",
]

exports.orderTitles = ["#", "Order number", "Order Date", "Customer", "Status", "Total", "Invoice"]
exports.orderDate = "Order date"
exports.toDateCanTBeBeforeFromDate = "To date can't be before from date"
exports.toAmountCanTBeLessThanFromAmount = "To amount can't be less than from amount"
exports.orderStatus = "Order status"
exports.placed = "Placed"
exports.delivered = "Delivered"
exports.refunded = "Refunded"
exports.cancelled = "Cancelled"
exports.from = "From"
exports.to = "To"
exports.resetAllButton = "Reset all"
exports.applyButton = "Apply"
exports.filterOrder = "Filter Order"
exports.orders = "Orders"
exports.downloadCsv = "Download CSV"
exports.all = "All"
exports.placed = "Placed"
exports.delivered = "Delivered"
exports.cancelled = "Cancelled"
exports.refunded = "Refunded"
exports.noOrders = "No orders"
exports.noOrdersMessage = "There are no orders to show at the moment, but don’t worry they’ll come in soon!"
exports.somethingWentWrong ='Something went wrong'
// Customizable Area End
